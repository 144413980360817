import React from "react";
import { useEffect } from "react";

const Documentation = () => {
  useEffect(() => {
    window.location.href =
      "https://documenter.getpostman.com/view/23730133/2s83zfPjpv";
  }, []);

  return <div></div>;
};

export default Documentation;
